<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Promo List
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top:27px;"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add()"
										id="btn"
										color="success"
										style="margin-top:27px; margin-left:5px;"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height:900px;">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								:detail-row-component="detailRow"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-discevent" slot-scope="props">
									{{ props.rowData.discEventDiscount }} <br />
									({{
										props.rowData.discEventValue
											? props.rowData.discEventValue.toLocaleString()
											: 0
									}})
								</template>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										style="margin-left:3px;"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
import TransactionOrderDetail from "./TransactionOrderDetail";

export default {
	data() {
		return {
			uri: basuri + "order",
			moreParams: { filter: null, product: null },
			loading: false,
			detailRow: TransactionOrderDetail,

			fields: [
				{
					name: "code",
					title: "Code",
					sortField: "code",
				},
				{
					name: "date",
					title: "Date",
				},
				{
					name: "recipient",
					title: "Recipient",
				},
				{
					name: "total",
					title: "TOTAL",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "slot-discevent",
					title: "Disc. Event",
					dataClass: "text-center",
				},
				{
					name: "ongkir",
					title: "ongkir",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "grandTotal",
					title: "Grand Total",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	mounted() {},
	watch: {
		"moreParams.filter": debounce(function(val) {
			this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, product: null };
			this.$refs.vuetable.refresh();
		},
		add() {},
		getProducts: debounce(async function() {}, 1000),
	},
};
</script>
